import { store as auth } from "@/modulos/auth";
import { store as principal } from "@/modulos/home";
import { store as curriculo } from "@/modulos/curriculo";
import { store as admin } from "@/modulos/admin";

export default{
    auth,
    principal,
    curriculo,
    admin,
   
}