export default{
    login:{
        method:'post',
        url:'user/login'
    },
    recupera:{
        method:'post',
        url:'login/esquecir/senha'
    },
    session:{
        method:'get',
        url:'user'
    },
    logout:{
        method:'get',
        url:'user/logout'
    },
    contrato:{
        method:'post',
        url:'contrata/mobe'
    },
}